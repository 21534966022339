

body {

  display: flex;
  background-color: #fcd2de;

}

#container {
  left: 0px;
  top: -100px;
  height: calc(100vh + 100px);
  overflow: hidden;
  position: relative;
}

#animate{
  margin: 0 auto;
  width: 20px;
  overflow: visible;
  position: relative;
}

#all{
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: fixed;
}

.noto-color-emoji-regular {
  font-family: "Noto Color Emoji", serif;
  font-weight: 400;
  font-style: normal;
}

.delius-regular {
  font-family: "Delius", serif;
  font-weight: 400;
  font-style: normal;
}