@import url('https://fonts.googleapis.com/css2?family=Delius&display=swap');

.envelope {
    position: relative;
    width: 100vw;
    /* height: ; */
    margin: 50px auto;
    cursor: pointer;
    perspective: 1000px;
    background: url('https://static.vecteezy.com/system/resources/previews/010/029/965/non_2x/love-letter-pattern-texture-with-text-effect-on-a-white-background-valentine-s-day-special-seamless-pattern-design-with-love-shapes-love-endless-pattern-decoration-for-bed-sheets-and-wallpapers-vector.jpg');
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.6s ease;
  }

  .flap {
    position: absolute;
    width: 100%;
    height: 50%;
    background: url('https://static.vecteezy.com/system/resources/previews/010/029/965/non_2x/love-letter-pattern-texture-with-text-effect-on-a-white-background-valentine-s-day-special-seamless-pattern-design-with-love-shapes-love-endless-pattern-decoration-for-bed-sheets-and-wallpapers-vector.jpg');
    transform-origin: bottom;
    transition: transform 0.8s ease;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .body {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    background: url('https://static.vecteezy.com/system/resources/previews/010/029/965/non_2x/love-letter-pattern-texture-with-text-effect-on-a-white-background-valentine-s-day-special-seamless-pattern-design-with-love-shapes-love-endless-pattern-decoration-for-bed-sheets-and-wallpapers-vector.jpg');
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: -1;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .letter {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #fafafa;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: bottom;
    z-index: -2;
    opacity: 0;
    font-family: "Delius", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    padding: 20px;
    box-sizing: border-box;
    text-align: justify;
  }

  .open .flap {
    transform: rotateX(-180deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }

  .letter.fullSize {
    top: 0%;
    height: 100%;  /* Expand to full screen */
    opacity: 1;
    z-index: -2;
    transform: scale(1);  /* Scale up if necessary */
    text-align: center;
  }
